<template>
  <RecommendationCarousel
    :class="attrs.class"
    :recommendation-query="{
      widgetId: widgetId,
      limit: 16,
      personalisation: true,
    }"
    :subtitle="subtitle"
    :title="title"
    :tracking-data="{
      list: listTrackingData,
    }"
    @error="onCarouselNoResults"
    @no-results="onCarouselNoResults"
  />
  <div v-if="hasCtaLink" class="flex justify-center">
    <CTALink :cta-link="ctaLink" width="fixed" />
  </div>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue'

import type { RecommendationsApiProps } from '@backmarket/http-api/src/api-specs-content/models/recommendations'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import { useListTrackingData } from '@backmarket/nuxt-layer-recommendation/useProductTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import CTALink from '../../shared-components/CTALink/CTALink.vue'

export type RecommendationsProps = RecommendationsApiProps

const attrs = useAttrs()

const props = defineProps<ContentBlockProps & RecommendationsProps>()
const listTrackingData = useListTrackingData({
  blockPosition: props.blockPosition,
})

const hasCtaLink = computed(() => !isEmpty(props.ctaLink))

// This event is common to every CMS block in order to hide the block containers
// on demand
const emit = defineEmits(['error'])

const onCarouselNoResults = () => {
  emit('error')
}
</script>
